import React from "react"
import { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import Logo from "../../logo"
import Heart from "../../heart"
import Dropdown from "../../dropdown/NewDropdown"

import MobileMenu from "../../mobile_menu/MobileMenu"
import { FavContext } from "../../../context/FavContext"
import { DropDown } from "../../../styles/components/header"
import BoxDown from "../../dropdown/BoxDown"

const Header = styled.header`
  max-width: 100%;
  height: 100%;
  background-color: #fff;
  position: sticky; // change positioning on scroll, animate it to with framer-motion
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  background-color: white;
`

const Navigation = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  @media (min-width: 1028px) {
    padding: 1.25rem 2rem;
  }
`

const Ul = styled.ul`
  
  list-style: none;
  display: none;
  > li:last-child {
    margin-left: 1rem;
  }
  @media (min-width: 1028px) {
    display: flex;
    align-items: center;
  }
`

const Li = styled.li`
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.25px;
  color: #000;


  > a {
    text-decoration: none;
    color: inherit;
  }
  :hover {  
  color: #B9789F;
  }
`

const DropdownWrapperLi = styled.li`
  background-color: ${props => props.color};
  width: 100%;
  padding: 1rem 0;
 
`

const DropdownLi = styled.li`
:hover {  
  color: #B9789F;
  }
`

const CustomLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 0 2rem .5rem 2rem;
`

const LogoWrapper = styled.a`
  width: 200px;
  display: inline-flex;
  align-items: center;
`

const NewHeader = () => {
  const { favs, added, setAdded } = useContext(FavContext)
  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false);
      }, 1500)
    }
  }, [added, setAdded])
  return (
    <Header>
      {/* // Navbar */}
      <Navigation>
        <MobileMenu />

        <LogoWrapper href="/">
          <Logo />
        </LogoWrapper>

        <Ul>
          {/* navitem */}
          <Dropdown category="Sofas" padding="1rem">
            <DropdownLi><CustomLink to="/sofas/1_5-2-3-sitzer-sofas">1,5-2-3-Sitzer Sofas</CustomLink></DropdownLi>
            <DropdownLi><CustomLink to="/sofas/sofas-mit-recamiere-und-ecksofas">Récamière- und Eckssofas</CustomLink></DropdownLi>
            <DropdownLi><CustomLink to="/sofas/schlafsofas">Schlafsofas</CustomLink></DropdownLi>
          </Dropdown>

          <Dropdown category="Möbel">

            <DropdownWrapperLi color="none">
            <ul>
            <DropdownLi><CustomLink to="/stuehle">Stühle</CustomLink></DropdownLi>
            <DropdownLi><CustomLink to="/sofas/sessel">Sessel</CustomLink></DropdownLi>
            <DropdownLi><CustomLink to="/baenke">Bänke</CustomLink></DropdownLi>
            </ul>
            </DropdownWrapperLi>

            <DropdownWrapperLi color="#eeeeee">
            <ul>
            <DropdownLi><CustomLink to="/tische/esstische">Esstische</CustomLink></DropdownLi>
            <DropdownLi><CustomLink to="/tische/couch-und-beistelltische">Couch- und Beistelltische</CustomLink></DropdownLi>
            <DropdownLi><CustomLink to="/tische/schreibtische">Schreibtische</CustomLink></DropdownLi>
            </ul>
            </DropdownWrapperLi>

            <DropdownWrapperLi color="inherit">
            <ul>
            <DropdownLi><CustomLink to="/aufbewahrung/sideboards">Sideboards</CustomLink></DropdownLi>
            <DropdownLi><CustomLink to="/aufbewahrung/schraenke">Schränke</CustomLink></DropdownLi>
            <DropdownLi><CustomLink to="/aufbewahrung/regale">Regale</CustomLink></DropdownLi>
            <DropdownLi><CustomLink to="/aufbewahrung/garderoben">Garderoben</CustomLink></DropdownLi>
            </ul>
            </DropdownWrapperLi>

            <DropdownWrapperLi color="#eeeeee" radius="0 0 0.75vw 0.75vw">
            <ul>
            <DropdownLi><CustomLink to="/betten">Betten</CustomLink></DropdownLi>
            </ul>
            </DropdownWrapperLi>

            <DropdownWrapperLi color="inherit" radius="0">
            <ul>
            <DropdownLi><CustomLink style={{color: "red"}} to="/sale">Sale</CustomLink></DropdownLi>
            </ul>
            </DropdownWrapperLi>

          </Dropdown>

          <Dropdown category="Wohnaccessoires" padding="1rem">
          <DropdownLi><CustomLink to="/accessoires/leuchten">Leuchten</CustomLink></DropdownLi>
            <DropdownLi><CustomLink to="/accessoires/dekoration">Dekoration</CustomLink></DropdownLi>
            <DropdownLi><CustomLink to="/accessoires/teppiche">Teppiche</CustomLink></DropdownLi>
          </Dropdown>

          

          <Li>
            <a href="/einrichtungsplanung">Einrichtungsplanung</a>
          </Li>
          </Ul>

        <Ul>
          <Li>
            <a href="/einrichtungshaus">Einrichtungshaus</a>
          </Li>

          <Dropdown category="Öffnungszeiten">
<BoxDown />
          </Dropdown>
        </Ul>

        <Link
          style={{
            position: "relative",
            display: "inline-block",
            marginRight: "1rem"
          }}
          to="/wunschliste"
        >
          <Heart />
          <div
            style={{
              fontSize: ".75rem",
              position: "absolute",
              bottom: "-6px",
              right: "-16px",
              padding: ".15rem .15rem .25rem .15rem",
              borderRadius: "100%",
              backgroundColor: "#ffffff",
              width: "20px",
              height: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#000",
              boxShadow: "1px 2px 4px lightgrey",
            }}
          >
            {favs.length}
          </div>

          {added && (
            <div
              style={{
                width: "250px",
                position: "absolute",
                top: "40px",
                right: 0,
                padding: "1rem",
                boxShadow: "1px 2px 4px lightgrey",
                backgroundColor: "#fff",
                fontSize: "1rem"
              }}
            >
              Zur Merkliste hinzugefügt.
            </div>
          )}
        </Link>
      </Navigation>
    </Header>
  )
}

export default NewHeader
