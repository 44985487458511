import * as React from "react"

function MobileMenuArrow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" {...props}>
      <path d="M140.27 64.44a3.47 3.47 0 00-.29-.25l-55.43-43A8.59 8.59 0 1074 34.71l37 28.7H8.79a8.59 8.59 0 100 17.18H111l-37 28.69a8.59 8.59 0 1010.54 13.57l55.43-43a3.47 3.47 0 00.29-.25 9.84 9.84 0 000-15.12z" />
    </svg>
  )
}

export default MobileMenuArrow