import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import MenuToggle from "./MenuToggle"
import MobileMenuArrow from "../../assets/MobileMenuArrow"

const Container = styled(motion.div)`
  display: block;
  @media (min-width: 1028px) {
    display: none;
  }
`

const Wrapper = styled(motion.div)`
  height: 91.5vh;
  background-color: #fff;
  position: fixed;
  top: 8.5vh;
  left: 0;
  z-index: 9999;
`

const Nav = styled(motion.nav)`
  width: 200vw;
  display: flex;
  overflow-x: hidden;
`

const Left = styled(motion.div)`
  width: 100vw;
  height: 91.5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const BottomContainer = styled.div`
  padding: 1rem;
  height: 30vh;
  width: 100vw;
  background-color: turquoise;
`

const BottomTitle = styled.div`
  font-size: 1rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 700;
  margin-bottom: 0.5rem;
`

const BottomLi = styled.li`
  font-size: 0.75rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 700;
  margin-bottom: 0.5rem;
`

const Right = styled(motion.div)`
  padding: 1rem;
  width: 100vw;
  height: 91.5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const MenuButton = styled.button`
  width: fit-content;
  cursor: pointer;
  border: none;
  background: inherit;
  padding: 1rem 0;
  font-size: 1.5rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 700;
  display: flex;
  align-items: center;
  /* :after {
    margin-left: 0.5rem;
    content: ">";
  } */
`

const ArrowWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  transform: ${props => props.rotate};
`

const MenuButtonReverse = styled.button`
  width: fit-content;
  cursor: pointer;
  border: none;
  background: inherit;
  padding: 1rem 0;
  font-size: 1.5rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 700;
  display: flex;
  align-items: center;
  /* :before {
    margin-right: 0.5rem;
    content: "<";
  } */
`

const CustomLink = styled(Link)`
  font-family: "Courier New", Courier, monospace;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: black;
`

const DropdownLi = styled.li`
  list-style: none;
  margin-bottom: 1rem;
  :hover {
    color: #b9789f;
  }
`

const DropdownWrapperLi = styled.li`
  list-style: none;
  /* background-color: ${props => props.color}; */
  width: 100%;
  padding: 1rem 0;
  border-radius: ${props => props.radius};
`

const variants = {
  closed: {
    display: "none",
    opacity: 0,
    transition: {
      duration: 0.35,
    },
  },
  open: {
    display: "block",
    opacity: 1,
    transition: {
      duration: 0.35,
    },
  },
}

const slideHorizontalAnimation = {
  left: {
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
  right: {
    x: "-100vw",
    transition: {
      duration: 0.3,
    },
  },
}

const MobileMenu = ({ data }) => {
  const [isOpen, toggleOpen] = useState(false)
  const [direction, setDirection] = useState(false)
  const [menu, setMenu] = useState("")

  // overflowX hidden on main

  // const handleOverflow = () => {

  // }

  return (
    <Container initial="closed" animate={isOpen ? "open" : "closed"}>
      <MenuToggle
        toggle={() => {
          toggleOpen(!isOpen)
          setDirection(false)
        }}
      />
      <Wrapper variants={variants}>
        <Nav
          initial="left"
          animate={direction ? "right" : "left"}
          variants={slideHorizontalAnimation}
        >
          <Left>
            <div
              style={{
                padding: "3rem 1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <MenuButton
                onClick={() => {
                  setDirection(true)
                  setMenu("Sofas")
                }}
              >
               <span>Sofas</span>
               <ArrowWrapper marginLeft=".5rem">
                <MobileMenuArrow />
                </ArrowWrapper>
              </MenuButton>
              <MenuButton
                onClick={() => {
                  setDirection(true)
                  setMenu("Möbel")
                }}
              >
                <span>Möbel</span>
                <ArrowWrapper marginLeft=".5rem">
                <MobileMenuArrow />
                </ArrowWrapper>
              </MenuButton>
              <MenuButton
                onClick={() => {
                  setDirection(true)
                  setMenu("Wohnaccessoires")
                }}
              >
                <span>Wohnaccessoires</span>
                <ArrowWrapper marginLeft=".5rem">
                <MobileMenuArrow />
                </ArrowWrapper>
              </MenuButton>

              <div
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                 <CustomLink style={{color: "red"}} to="/sale">
                  Sale
                </CustomLink>
                <CustomLink to="/einrichtungsplanung">
                  Einrichtungsberatung
                </CustomLink>
                <CustomLink to="/einrichtungshaus">Einrichtungshaus</CustomLink>
              </div>

              <div
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CustomLink to="/kontakt">Kontakt</CustomLink>
                <CustomLink to="/impressum">Impressum</CustomLink>
                <CustomLink to="/datenschutz">Datenschutz</CustomLink>
                <CustomLink to="/allgemeine-geschaeftsbedingungen">AGB</CustomLink>
              </div>
            </div>

            {/* <BottomContainer>

              <div>
              <BottomTitle>Kontakt:</BottomTitle>
              <ul>
                <BottomLi>Haupteingang: Immermannstraße 6</BottomLi>
                <BottomLi>Nebeneingang: Klosterstraße 31</BottomLi>
                <BottomLi>40210 Düsseldorf</BottomLi>
              </ul>
              </div>

              <div>
              <BottomTitle>Öffnungszeiten:</BottomTitle>
              <ul>
              <BottomLi>Montag: Termine nach Vereinbarung</BottomLi>
              <BottomLi>Dienstag: 10:00 bis 19:00 Uhr</BottomLi>
              <BottomLi>Samstag: 10:00 bis 18:00 Uhr</BottomLi>
              <BottomLi>Sonntag: geschlossen</BottomLi>
              </ul>
              </div>
            
            </BottomContainer> */}
          </Left>

          <Right>
            <div
              style={{
                padding: "2rem 0",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <MenuButtonReverse onClick={() => setDirection(false)}>
              <ArrowWrapper marginRight=".5rem" rotate="rotate(180deg)">
                <MobileMenuArrow rotate="rotate(180deg)" />
                </ArrowWrapper>
                <span>{menu}</span>
         
              </MenuButtonReverse>

              {menu === "Sofas" && (
                <ul>
                  <DropdownLi>
                    <CustomLink to="/sofas/1_5-2-3-sitzer-sofas">
                      1,5-2-3-Sitzer Sofas
                    </CustomLink>
                  </DropdownLi>
                  <DropdownLi>
                    <CustomLink to="/sofas/sofas-mit-recamiere-und-ecksofas">
                      Récamière- und Eckssofas
                    </CustomLink>
                  </DropdownLi>
                  <DropdownLi>
                    <CustomLink to="/sofas/schlafsofas">Schlafsofas</CustomLink>
                  </DropdownLi>
                </ul>
              )}
              {menu === "Möbel" && (
                <>
                  <DropdownWrapperLi color="none">
                    <ul style={{ listStyle: "none" }}>
                      <DropdownLi>
                        <CustomLink to="/stuehle">Stühle</CustomLink>
                      </DropdownLi>
                      <DropdownLi>
                        <CustomLink to="/sofas/sessel">Sessel</CustomLink>
                      </DropdownLi>
                      <DropdownLi>
                        <CustomLink to="/baenke">Bänke</CustomLink>
                      </DropdownLi>
                    </ul>
                  </DropdownWrapperLi>

                  <DropdownWrapperLi color="#eeeeee">
                    <ul style={{ listStyle: "none" }}>
                      <DropdownLi>
                        <CustomLink to="/tische/esstische">
                          Esstische
                        </CustomLink>
                      </DropdownLi>
                      <DropdownLi>
                        <CustomLink to="/tische/couch-und-beistelltische">
                          Couch- und Beistelltische
                        </CustomLink>
                      </DropdownLi>
                      <DropdownLi>
                        <CustomLink to="/tische/schreibtische">
                          Schreibtische
                        </CustomLink>
                      </DropdownLi>
                    </ul>
                  </DropdownWrapperLi>

                  <DropdownWrapperLi color="inherit">
                    <ul style={{ listStyle: "none" }}>
                      <DropdownLi>
                        <CustomLink to="/aufbewahrung/sideboards">
                          Sideboards
                        </CustomLink>
                      </DropdownLi>
                      <DropdownLi>
                        <CustomLink to="/aufbewahrung/schraenke">
                          Schränke
                        </CustomLink>
                      </DropdownLi>
                      <DropdownLi>
                        <CustomLink to="/aufbewahrung/regale">
                          Regale
                        </CustomLink>
                      </DropdownLi>
                      <DropdownLi>
                        <CustomLink to="/aufbewahrung/garderoben">
                          Garderoben
                        </CustomLink>
                      </DropdownLi>
                    </ul>
                  </DropdownWrapperLi>

                  <DropdownWrapperLi color="#eeeeee" radius="0 0 0.75vw 0.75vw">
                    <ul style={{ listStyle: "none" }}>
                      <DropdownLi>
                        <CustomLink to="/betten">Betten</CustomLink>
                      </DropdownLi>
                    </ul>
                  </DropdownWrapperLi>
                </>
              )}
              {menu === "Wohnaccessoires" && (
                <ul style={{marginBottom: "1rem"}}>
                  <DropdownLi>
                    <CustomLink to="/accessoires/leuchten">Leuchten</CustomLink>
                  </DropdownLi>
                  <DropdownLi>
                    <CustomLink to="/accessoires/dekoration">
                      Dekoration
                    </CustomLink>
                  </DropdownLi>
                  <DropdownLi>
                    <CustomLink to="/accessoires/teppiche">Teppiche</CustomLink>
                  </DropdownLi>
                </ul>
              )}
            </div>
          </Right>
        </Nav>
      </Wrapper>
    </Container>
  )
}

export default MobileMenu
