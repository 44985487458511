import React from 'react';
import { Link } from 'gatsby';
import {
    FooterContainer,
    FooterWrapper,
    SpecialFooterItem,
    FooterItem,
    FooterLink,
    Section,
    SpecialSection,

    FooterSubline,
    SocialMedia,

} from '../styles/components/footer'
import YoutubeIcon from '../assets/YoutubeIcon';
import FacebookIcon from '../assets/FacebookIcon';
import PinterestIcon from '../assets/PinterestIcon';
import InstagramIcon from '../assets/InstagramIcon';

import Landeswappen from '../assets/nrw_w_rgb.png'

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrapper>

                <Section>
                    <FooterSubline>Kontakt</FooterSubline>
                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "3rem", fontSize: "1.2rem" }}>
                        <SpecialFooterItem><FooterLink href="tel:004921141651600">Telefon: 0211 41651600</FooterLink></SpecialFooterItem>
                        <SpecialFooterItem><FooterLink href="mailto:info@diewohnplaner.de">info@diewohnplaner.de</FooterLink></SpecialFooterItem>
                    </div>
                    <FooterSubline>Einrichtungshaus</FooterSubline>
                    <div style={{ marginBottom: "3rem" }}>
                        <FooterItem>Haupteingang: Immermannstraße 6</FooterItem>
                        <FooterItem>Nebeneingang: Klosterstraße 31</FooterItem>
                        <FooterItem>40210 Düsseldorf</FooterItem>
                    </div>
                    <FooterSubline>Öffnungszeiten</FooterSubline>
                    <div>
                      
                        <FooterItem>Dienstag bis Freitag: 10:30-19:00</FooterItem>
                        <FooterItem>Samstag: 10:30-18:00</FooterItem>
                        <FooterItem>Montag und Sonntag: geschlossen</FooterItem>
                    </div>
                </Section>

                <SpecialSection>
                    <div>
                        <FooterItem><FooterLink to="/einrichtungsplanung">Einrichtungsplanung</FooterLink></FooterItem>
                        <FooterItem><FooterLink to="/ueber-diewohnplaner">Über uns</FooterLink></FooterItem>
                        <FooterItem><a href="https://www.dieobjektplaner.de">dieobjektplaner</a></FooterItem>
                    </div>
                    <SocialMedia>
                        <a href="https://www.facebook.com/diewohnplaner/?ref=bookmarks"><FacebookIcon /></a>
                        <a href="https://www.instagram.com/diewohnplaner/"><InstagramIcon /></a>
                        <a href="https://www.pinterest.de/diewohnplaner/"><PinterestIcon /></a>
                        <a href="https://www.youtube.com/channel/UCaZ9xPjGMl42chQeJM0UWsw"><YoutubeIcon /></a>
                    </SocialMedia>
                </SpecialSection>

                <SpecialSection>
                    <div>
                        <FooterItem><FooterLink to="/impressum">Impressum</FooterLink></FooterItem>
                        <FooterItem><FooterLink to="/datenschutz">Datenschutz</FooterLink></FooterItem>
                        <FooterItem><FooterLink to="/allgemeine-geschaeftsbedingungen">AGB</FooterLink></FooterItem>
                    </div>

                    <div>
                        <img 
                        width="50" 
                        src={Landeswappen} 
                        alt="" />
                        <div>Gefördert durch das Land Nordrhein-Westfalen</div>
                    </div>
                </SpecialSection>



            </FooterWrapper>

        </FooterContainer>
    );
}

export default Footer;