import styled from "styled-components"
import { motion } from "framer-motion"

export const HeaderContainer = styled(motion.header)`
  position: relative;
`

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  height: 56px;
  display: flex;
  align-items: center;
  z-index: 1200;
  @media (min-width: 600px) {
    height: 76px;
  }
`

export const NavigationContainer = styled.nav`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    @media (min-width: 1280px) {
      padding-left: 26px;
      padding-right: 26px;
    }
`

export const Ul = styled.ul`
  display: none;
  li:not(:first-child) {
    margin-left: 14px;
  }
  @media (min-width: 1280px) {
    flex: 1 1 0%;
    display: flex;
    height: 100%;
  }
`

export const MobileNavContainer = styled.ul`
  display: flex;
  align-items: center;
  @media (min-width: 1280px) {
    flex: 1 1 0%;
  }
`

export const LogoWrapper = styled.h1`
    flex: none;
    width: 65%;
  @media (min-width: 1280px) {
    flex: 1 1 0%;
    text-align: center;
    width: 200px;
  }
`

export const InnerLogoWrapper = styled.div`
 margin: unset;
 width: unset;
 @media (min-width: 600px) {
    width: 260px;
    margin: unset;
    margin-top: .7rem;
  }
  @media (min-width: 1280px) {
    width: 260px;
    margin: 0 auto;
    margin-top: .7rem;
  }
`

export const MobileUl = styled.ul`
  display: flex;
  align-items: center;
`

export const DropDown = styled(motion.div)`
  z-index: 1199;
  position: absolute;
  top: 72px;
  left: 0;
  width: 100%;
  height: 65vh;
  background-color: rgba(255, 255, 255);
  /* border-top: .5px solid #000; */
  border-bottom: .5px solid #000;
`

export const DropDownUl = styled(motion.ul)`
  padding-top: 36px; 
  padding-left: 36px;
  padding-right: 36px;
  display: flex;
  align-items: center;
`

export const DropDownLi = styled(motion.li)`
  font-size: 1.4rem;
  max-width: 25%;
`

export const NavButton = styled.button`
  border: none;
  background: inherit;
  position: relative;
  cursor: pointer;
  font-size: 0.9rem;
  letter-spacing: 1px;
  color:  ${({ theme }) => theme.textColor};
  :hover {
    color: lightgrey;
  }
`

export const NavItemContainer = styled.div`
   display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start;
`