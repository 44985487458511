import styled from "styled-components"
import { Link } from 'gatsby'
import { motion } from "framer-motion"

export const MobileNavigation = styled(motion.nav)`
    position: absolute;
    right: 0;
    top: 56px;
    bottom: 0;
    background-color: #fff;
    z-index: 1000;
    max-width: 100%;
    padding: 16px;
    overflow-x: hidden;
    height: 94vh;
    color: #fff;
    background-color: rgb(35, 152, 171);
    @media (min-width: 600px) {
      max-width: 50%;
      padding: 26px;
      top: 76px;
    }
`


export const MenuOverlay = styled(motion.div)`
 
 `

export const MobileMenuList = styled(motion.ul)`
    
`

// MenuItem - sollen gestaggert reinfahren.

export const MobileMenuItem = styled(motion.li)`
    font-size: 2rem;
    
`

export const MenuButton = styled.button`
    width: 36px;
    height: 36px;
    /* width: fit-content; */
    outline: none;
    border: none;
    cursor: pointer;
    z-index: 2000;
    overflow: visible;
   
    border-radius: 50%;
    background: transparent;
`

export const MobileMenuLink = styled(Link)`
    cursor: pointer;
    font-size: ${( props ) => props.fontSize};
    width: 100%;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    font-family: acumin-pro-wide, sans-serif;
`
