import React from "react"



const Heart = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 26" style={{ width: "25px", fill: "white", stroke: "#ed1c24", strokeLinecap: "round", strokeWidth: "2px" }} whileHover={{fill: "red", scale: 2}}><g><path  d="M25.27,14.77,15,25,4.79,14.77C-4.29,5.69,6-4.56,15,4.53,24-4.46,34.26,5.78,25.27,14.77Z"/></g></svg>
  )
}

export default Heart
