/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Link } from 'gatsby'

import CookieConsent, { Cookies } from 'react-cookie-consent';
import styled from 'styled-components';

import Header from "./header"
import Footer from "./footer"
import { Main } from "../styles/GlobalStyle"

import { useLocation } from "@reach/router" 
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import WhatsAppLogo from "../assets/WhatsApp_Logo"
import NewHeader from "./index/Header/NewHeader";

const cookieContainer = {
  margin: "1rem",
  display: "inline-block",
  backgroundColor: "#fff",
  borderRadius: "20px",
  color: "#000x",
  // display: "flex",
  // alignItems: "center",
  fontSize: ".75rem",
  position: "fixed",
  // left: "50%",
  // transform: "translateX(-50%)",
  maxWidth: "400px"
}

const FloatingActionButton = styled.button`
position: fixed;
right: 0;
bottom: 0;
margin-right: 20px;
margin-bottom: 20px;
z-index: 1001;
width: 64px;
height: 64px;
background: none;
border: none;
cursor: pointer;
`

const Layout = ({ children }) => {
  const location = useLocation()
  return (
    <>

      <FloatingActionButton>
      <a href="https://api.whatsapp.com/send?phone=4915236611286" title="Senden Sie uns eine Nachricht über WhatsApp!" target="_blank" rel="noreferrer noopener">
      <WhatsAppLogo />
      </a>
      </FloatingActionButton>


      <NewHeader />
      {/* <Header /> */}

      {/* sticky floating button container! */}

      <Main>
        {children}
      </Main>
      <Footer />

      <CookieConsent

        location="bottom"
        buttonText="Akzeptieren"
        // declineButtonText="Ablehnen"
        // enableDeclineButton
        cookieName="gatsby-gdpr-google-tagmanager"
        sameSite="none"
        // declineButton
        debug={false}
        style={cookieContainer}
        buttonStyle={{ borderRadius: "8px", boxShadow: "1px 2px 4px lightgrey", color: "#000", backgroundColor: "#fff", padding: ".5rem 1rem", fontWeight: "bold" }}
        // declineButtonStyle={{ borderRadius: "8px", border: "1px solid #8C8C8C", color: "#8C8C8C", backgroundColor: "inherit", padding: "1rem", fontWeight: "bold" }}
        onAccept={() => {
          Cookies.set('gatsby-gdpr-google-tagmanager', true);
          initializeAndTrack(location)
        }}
      >


      
        {`Diese Webseite nutzt Cookies. `}
   

        <Link style={{
          color: "blue",
        }} to="/datenschutz">Datenschutzerklärung.
        </Link>

      </CookieConsent>



    </>
  )
}

export default Layout
