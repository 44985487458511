import React from "react"
import styled from "styled-components"

const Container = styled.li`
  position: relative;
  z-index: 9000;
`

const HoverParent = styled.div`
  position: relative;
`

const Button = styled.button`
  cursor: pointer;
  padding: .5rem 1rem .5rem 1rem;
  border: none;
  background: inherit;
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.25px;
  color: #000;
  /* :after {
      transform: rotate(90deg);
      content: "^";
    } */
  ${HoverParent}:hover & {
    border-radius: 0.25vw;
    background-color: #f3f3f3b2;
  }
`

const MenuContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  filter: drop-shadow(0px 3px 6px rgba(153, 153, 153, 0.75));
  display: none;
  visibility: hidden;
  ${HoverParent}:hover & {
    display: block;
    visibility: visible;
  }
`

const Menu = styled.ul`
  color: #2e2e2e;
  font-weight: 500;
  letter-spacing: 1px;
  padding-top: ${props => props.padding};
  padding-bottom: ${props => props.padding};
  height: 100%;
  width: 440px;
  background-color: #fff;
  border-radius: 0.75vw;
  > li {
    display: flex;
    align-items: center;
  }
  /* li:nth-child(even) {
    width: 100%;
    background-color: #eeeeee;
  } */
`

const MenuItem = styled.a`
  width: 100%;
  padding: 3rem;
  :hover {
    /* :after {
      content: ">"
    } */
  }
`

const ArrowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MenuArrow = styled.div`
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  width: 30px;
  height: 10px;
  background-color: #fff;
`

const Dropdown = ({ category, children, padding }) => {
  return (
    <Container>
      <HoverParent>
        <Button>{category}</Button>
        <MenuContainer>
          <ArrowWrapper>
            <MenuArrow />
          </ArrowWrapper>
          <Menu padding={padding}>
           {children}
          </Menu>
        </MenuContainer>
      </HoverParent>
    </Container>
  )
}

export default Dropdown
