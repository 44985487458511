import styled from 'styled-components'
import { Link } from 'gatsby';


export const FooterContainer = styled.footer`
    min-width: 100%;
    background-color: rgb(35,152,171);
    height: 100%;
    color: #FAFAF2;
    font-size: 0.8rem;
    `
export const FooterWrapper = styled.div`
    padding: 36px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    @media (min-width: 600px) {
        flex-direction: row;
      }
`
export const Section = styled.section`
      
`

export const SpecialSection = styled.section`
      display: flex;
      flex-direction: column;
      justify-content: space-between; 
      margin-top: 1rem;
      @media (min-width: 1280px) {
        margin-top: 0;
      }
`

export const SpecialFooterItem = styled.button`
      margin-bottom: 0.6rem;
      font-size: 1.1rem;
      padding: 1rem;
      border: 1px solid white;
      border-radius: 5px;
      background: none;
      color: #fff;
      cursor: pointer;
      :hover {
           color: #EEDC7C;
           border: 1px solid #EEDC7C
      }
`

export const FooterItem = styled.div`
      margin-bottom: 0.6rem;
      font-size: 1rem;
`

export const FooterLink = styled(Link)`
      :hover {
            color: #EEDC7C;
      }
`
export const FooterHeadline = styled.h3`
      font-size: 1.4rem;
      margin-bottom: 1rem;
`
export const FooterSubline = styled.h4`
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;

      font-weight: 600;
`

export const SocialMedia = styled.div`
      display: flex;
      margin-bottom: 0.6rem;
      margin-top: 1rem;
      a:not(:first-child) {
            margin-left: 12px;
      }    
      @media (min-width: 1280px) {
        margin-top: 0;
      }
`