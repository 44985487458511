import * as React from "react"
import { motion } from "framer-motion"

function YoutubeIcon(props) {
  return (
    <svg
      viewBox="0 -77 512.002 512"
      
      xmlns="http://www.w3.org/2000/svg"
      width="32px"
      height="32px"
      {...props}
    >
      <motion.path
        whileHover={{ fill: "red" }}
        d="M501.453 56.094c-5.902-21.934-23.195-39.223-45.125-45.13C416.262 0 255.996 0 255.996 0S95.734 0 55.668 10.547C34.16 16.449 16.445 34.164 10.543 56.094 0 96.156 0 179.242 0 179.242s0 83.504 10.543 123.149c5.906 21.93 23.195 39.222 45.129 45.129C96.156 358.484 256 358.484 256 358.484s160.262 0 200.328-10.546c21.934-5.903 39.223-23.196 45.129-45.125C512 262.745 512 179.663 512 179.663s.422-83.508-10.547-123.57zm0 0"
        fill="#8C8C8C"
      />
      <path d="M204.969 256l133.27-76.758-133.27-76.758zm0 0" fill="#fff" />
    </svg>
  )
}

export default YoutubeIcon
