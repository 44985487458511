import React from "react"

const Logo = () => {
  return (
    <svg
      width="200px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 278.3 36.5"
      style={{fill: "#EB3629"}}
    >
      <g>
        <g>
          <path
            
            d="M15,25.8L15,25.8c-1,1.4-3.2,3.2-6.7,3.2C2.5,29,0,23.8,0,18.5c0-5.2,2.7-9.8,8.4-9.8c3.4,0,5.3,1.3,6.4,3.1
			h0.1V0h3.6v28.5H15V25.8z M9.2,26.1c4,0,5.7-4,5.7-7.2c0-3.6-1.9-7.3-5.7-7.3s-5.4,3.7-5.3,7.2C3.7,21.8,5.3,26.1,9.2,26.1z"
          />
          <path
            
            d="M28.8,4.8h-4v-4h4V4.8z M25,9.1h3.6v19.4H25V9.1z"
          />
          <path
            
            d="M50,27.7C48.6,28.2,47,29,43.8,29c-6.9,0-10-4.2-10-10.5c0-5.7,3.6-9.8,9.1-9.8c6.5,0,8.6,4.7,8.6,11H37.6
			c0,3.8,3,6.5,6.4,6.5c2.4,0,5.1-1.2,6-1.9V27.7z M47.6,17c0-3-1.7-5.4-4.8-5.4c-3.5,0-4.9,2.9-5.2,5.4H47.6z"
          />
          <path
            
            d="M53.5,8.7h7.1L63.9,23h0.1l3.4-14.3h8.2L78.9,23H79l3.1-14.3h6.6l-6,19.8h-7.9l-3.3-14.3h-0.1l-3.8,14.3h-7.9
			L53.5,8.7z"
          />
          <path
            
            d="M90.2,18.6c0-6.6,5-10.4,11.5-10.4c6.5,0,11.5,3.8,11.5,10.4c0,6.5-5,10.3-11.5,10.3
			C95.2,29,90.2,25.2,90.2,18.6z M106.2,18.6c0-3-1.3-5.6-4.4-5.6s-4.4,2.6-4.4,5.6c0,2.9,1.3,5.6,4.4,5.6S106.2,21.6,106.2,18.6z"
          />
          <path
            
            d="M116.6,0h6.8v11.6h0.1c1.5-2.1,3.6-3.4,6.6-3.4c5.4,0,7.6,3.8,7.6,7.9v12.4h-6.8v-9.6c0-3.7-1.3-5-3.2-5
			c-2.8,0-4.3,1.9-4.3,6.2v8.4h-6.8V0z"
          />
          <path
            
            d="M141.9,8.7h6.2v3.6h0.1c1.4-2.9,4.1-4.1,7.2-4.1c5.4,0,7.6,3.8,7.6,7.9v12.4h-6.8v-9.6c0-3.7-1.3-5-3.2-5
			c-2.8,0-4.3,1.9-4.3,6.2v8.4h-6.8V8.7z"
          />
          <path
            
            d="M168.2,9.1h3.4v2.7h0.1c0.9-1.4,3.2-3.2,6.6-3.2c5.6,0,8.4,4.6,8.4,9.8c0,5.3-2.5,10.5-8.3,10.5
			c-3.4,0-5.4-1.3-6.5-3.1h-0.1v10.6h-3.6V9.1z M177.5,11.6c-3.9,0-5.7,3.7-5.7,7.3c0,3.2,1.7,7.2,5.7,7.2c3.8,0,5.4-4.3,5.4-7.3
			C182.9,15.2,181.4,11.6,177.5,11.6z"
          />
          <path d="M191.9,0h3.6v28.5h-3.6V0z" />
          <path
            
            d="M202.9,10.5c1.8-1.1,3.9-1.8,6.4-1.8c5.6,0,7.8,2.7,7.8,8v8c0,2.2,0.1,3.2,0.2,3.8h-3.3V26h-0.1
			c-0.8,1.2-2.7,3-6,3c-4.2,0-7.1-1.8-7.1-6c0-4.9,5.3-6.4,9-6.4c1.4,0,2.4,0,3.8,0.1c0-3.3-1.2-5.1-4.8-5.1c-2,0-4.2,0.8-5.6,2
			L202.9,10.5z M213.5,19.3c-0.8,0-1.5-0.1-2.3-0.1c-1.9,0-6.7,0.3-6.7,3.7c0,2.1,2,3.2,3.6,3.2c3.5,0,5.4-2.2,5.4-5.1V19.3z"
          />
          <path
            
            d="M223.1,9.1h3.5v3.1h0.1c1.1-2.2,3.7-3.5,6.3-3.5c5,0,7.3,3.1,7.3,8.3v11.5h-3.6v-10c0-4.5-1-6.7-4.1-6.9
			c-4.1,0-5.9,3.3-5.9,8v9h-3.6V9.1z"
          />
          <path
            
            d="M261.2,27.7c-1.4,0.5-2.9,1.3-6.1,1.3c-6.9,0-10-4.2-10-10.5c0-5.7,3.6-9.8,9.1-9.8c6.5,0,8.6,4.7,8.6,11
			h-13.8c0,3.8,3,6.5,6.4,6.5c2.4,0,5.1-1.2,6-1.9V27.7z M258.9,17c0-3-1.7-5.4-4.8-5.4c-3.5,0-4.9,2.9-5.2,5.4H258.9z"
          />
          <path
            
            d="M267.5,9.1h3.3v3h0.1c1-2,3-3.5,5.1-3.5c1.1,0,1.6,0.1,2.4,0.3v3.2c-0.6-0.3-1.4-0.3-2-0.3
			c-3.2,0-5.3,3-5.3,7.9v8.8h-3.6V9.1z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
