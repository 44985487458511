import React from "react"
import { motion } from "framer-motion"
import { MenuButton } from "../../styles/components/mobileMenu"

const Path = (props) => (
  <motion.path
    // fill="transparent"
    strokeWidth="2.5"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    style={{ overflow: "visible" }}
    {...props}
  />
)

const upperPathVariants = {
  closed: {
    d: "M 2 2.5 L 20 2.5",
    transition: {
      duration: 0.35,
    },

  },
  open: {
    d: "M 3 16.5 L 17 2.5",
    transition: {
      duration: 0.35,
    },
  }
}

const lowerPathVariants = {
  closed: {
    d: "M 2 16.346 L 20 16.346",
    transition: {
      duration: 0.35,
    },

  },
  open: {
    d: "M 3 2.5 L 17 16.346",
    transition: {
      duration: 0.35,
    },
  }
}


const MenuToggle = ({ toggle }) => {
  return (
    <MenuButton onClick={toggle}>
      <svg
        style={{ overflow: "visible" }}
        viewBox="0 0 24 20">
        <Path
          variants={upperPathVariants}
        />
        <Path
          variants={lowerPathVariants}
        />
      </svg>
    </MenuButton>
  )
}

export default MenuToggle
