import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const Container = styled.div`
  padding: 1rem 2rem;
`

const Span = styled.span`
  display: inline-block;
  margin-bottom: 1rem;
`

const OpenClosedWrapper = styled.div`
  padding: .5rem;
  border: 1px solid lightgrey;
  border-radius: 8px;
`

const date = new Date().toLocaleTimeString()
const day = new Date().getDay()

const BoxDown = props => {
  const [open, setOpen] = useState(false)
  const [openClosed, setOpenClosed] = useState()

  useEffect(() => {
    if (date <= "19:00" && date >= "08:00" || day === 7 ) {
      setOpenClosed(true)
    } 
    else {
      setOpenClosed(false)
    }
  }, [])
  return ( <Container>

              <Span><strong>Dienstag bis Freitag:</strong> 10:30-19:00</Span><br />
              <Span><strong>Samstag:</strong> 10:30-18:00</Span><br />
              <Span><strong>Montag und Sonntag:</strong> geschlossen</Span><br />

              <OpenClosedWrapper>
              {openClosed === true && <div><span style={{marginRight: ".5rem"}} aria-label="Daumen hoch" role="img">👍</span>Jetzt geöffnet!</div>}
              {openClosed === false && <div><span style={{marginRight: ".5rem"}} aria-label="Daumen runter" role="img">👎</span> Jetzt geschlossen!</div>}
              </OpenClosedWrapper>
           
    </Container>
  )
}

export default BoxDown
